<template>
  <div class="user-manage">
    <div class="query-form">
      <query-form :form="form" v-model="wharf" @handleQuery="handleQuery" />
      <!-- <el-form ref="form" :inline="true" :model="wharf">
        <el-form-item label="码头名称" prop="wharfName">
          <el-input v-model="wharf.wharfName" placeholder="请输入码头名称"/>
        </el-form-item>
        <el-form-item label="部门" prop="deptName">
          <el-select v-model="wharf.deptName">
            <el-option value="" label="xx部门"></el-option>
            <el-option :value="0" label="aa部门"></el-option>
            <el-option :value="0" label="bb部门"></el-option>
            <el-option :value="0" label="cc部门"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" prop="createTime">
          <el-date-picker
            v-model="wharf.createTime"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="handleReset('form')">重置</el-button>
        </el-form-item>
      </el-form> -->
    </div>
    <base-table
    :columns="columns"
    :data="wharfList"
    :pager="pager"
    @selection-change="handleSelectionChange"
    @handleAction="handleAction"
    @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:action>
       <el-button type="primary" @click="handleCreate">新增</el-button>
      </template>
    </base-table>
    <!-- <div class="base-table">
      <div class="action">
        <el-button type="primary" @click="handleCreate">新增</el-button>
      </div>
      <el-table
        :data="wharfList">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="item.width">
        </el-table-column>
         <el-table-column label="操作" width="150px">
           <template #default="scope">
             <el-button @click="handleClick(scope.row)" type="parmary" size="mini">设置</el-button>
              <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
           </template>
         </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        layout="prev,pager,next"
        :total="pager.total"
        :page-size="10"
        @current-change="handleCurrentChange"
      />
    </div> -->
    <el-dialog title="新增码头" v-model="showModal">
      <el-form ref="dialogForm" :model="wharfForm" label-width="100px" :rules="rules">
        <el-form-item label="码头编号" prop="parentWharfId">
          <el-input v-model="wharfForm.parentWharfId" placeholder="请输入码头编号"/>
        </el-form-item>
        <el-form-item label="码头名称" prop="wharfName">
          <el-input v-model="wharfForm.wharfName" placeholder="请输入码头名称"/>
        </el-form-item>
        <el-form-item label="所属部门" prop="deptId">
          <el-select v-model="wharfForm.deptId">
            <el-option :value="xxx" label="票务部"></el-option>
            <el-option :value="xxx" label="运营部"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRaw } from 'vue'
import utils from './../../utils/utils'
export default {
  name: 'user',
  setup () {
    // 获取Composition API 上下文对象
    const { proxy } = getCurrentInstance()
    const form = [
      {
        type: 'input',
        label: '码头名称',
        model: 'wharfName',
        placeholder: '请输入码头名称'
      }
      // {
      //   type: 'select',
      //   label: '部门',
      //   model: 'deptName',
      //   placeholder: '请选择部门',
      //   options: [
      //     {
      //       label: '部门一',
      //       value: 1
      //     },
      //     {
      //       label: '部门二',
      //       value: 2
      //     }
      //   ]
      // }
    ]
    // 初始化表单对象
    const wharf = ref({})
    // 初始化列表对象
    const wharfList = ref([])
    // 初始化分页对象
    const pager = reactive({
      pageNum: 1,
      pageSize: 10
    })
    // 弹框显示对象
    const showModal = ref(false)
    // 新增Form对象
    const wharfForm = reactive({})
    const action = ref('add')
    // 定义表单校验规则
    const rules = reactive({
      wharfName: [
        {
          required: true,
          message: '请输入码头名称',
          trigger: 'blur'
        }
      ]
    })
    // 定义动态表格-格式
    const columns = reactive([
      {
        type: 'selection'
      },
      {
        label: '码头编号',
        prop: 'parentWharfId'
      },
      {
        label: '码头名称',
        prop: 'wharfName'
      },
      {
        label: '所属部门',
        prop: 'deptName'
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter (row, column, value) {
          return utils.formateDate(value)
        }
      },
      {
        type: 'action',
        label: '操作',
        list: [
          {
            type: 'primary',
            text: '设置',
            visible: true
          },
          {
            type: 'danger',
            text: '删除',
            visible: true
          }
        ]
      }
    ])
    // 初始化接口调用
    onMounted(() => {
      getWharfList()
    })
    // 获取码头列表
    const getWharfList = async (values) => {
      let params
      // if (JSON.stringify(toRaw(wharf.value)) === '{}') {
      //   params = {
      //     page: {
      //       skip: (pager.pageNum - 1) * pager.pageSize,
      //       limit: pager.pageSize
      //     }
      //   }
      // } else {
      //   params = {
      //     page: {
      //       skip: (pager.pageNum - 1) * pager.pageSize,
      //       limit: pager.pageSize
      //     },
      //     condition: {
      //       ...wharf.value
      //     }
      //   }
      // }
      if (values === undefined || values === {}) {
        params = {
          page: {
            skip: (pager.pageNum - 1) * pager.pageSize,
            limit: pager.pageSize
          }
        }
      } else {
        params = {
          page: {
            skip: (pager.pageNum - 1) * pager.pageSize,
            limit: pager.pageSize
          },
          condition: values
        }
      }

      // const res = await proxy.$api.getWharfList(params)
      // console.log(res)
      try {
        const res = await proxy.$api.getWharfList(params)
        console.log(res.data)
        wharfList.value = res.data
        pager.total = res.totalNum
      } catch (error) {
        console.log(error)
      }
    }
    // 查询事件， 获取码头列表
    const handleQuery = (values) => {
      const newValues = utils.checkParmas(values)
      getWharfList(newValues)
    }
    // 重置查询表单
    const handleReset = (form) => {
      proxy.$refs[form].resetFields()
      getWharfList()
    }
    // 分页事件处理
    const handleCurrentChange = (current) => {
      pager.pageNum = current
      getWharfList()
    }
    // 单个删除
    const handleDel = async (row) => {
      await proxy.$api.delWharfList({ _id: row._id })
      proxy.$message.success('删除成功')
      getWharfList()
    }
    // 新增表单
    const handleCreate = () => {
      showModal.value = true
    }
    // 弹框关闭
    const handleClose = () => {
      showModal.value = false
      handleReset('dialogForm')
    }
    // 处理按钮
    const handleAction = (values) => {
      console.log(values)
      if (values.index === 0) {
        console.log(values)
      } else if (values.index === 1) {
        handleDel(values.row)
      }
    }
    // 弹框提交
    const handleSubmit = () => {
      proxy.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          const userAction = action.value
          console.log(userAction)
          const params = toRaw(wharfForm)
          console.log(params)
          //  临时代码 begin
          params.deptId = '123'
          params.deptName = '测试'
          params.tenantCode = 'SDPARK'
          params.updateBy = '张三'
          //  临时代码 end
          const res = await proxy.$api.addWharfList(params)
          if (res) {
            showModal.value = false
            proxy.$message.success('码头创建成功')
            handleReset('dialogForm')
            getWharfList()
          }
        }
      })
    }
    return {
      form,
      wharf,
      wharfList,
      columns,
      getWharfList,
      pager,
      handleQuery,
      handleReset,
      handleCurrentChange,
      handleDel,
      handleCreate,
      showModal,
      wharfForm,
      rules,
      handleClose,
      handleSubmit,
      action,
      handleAction
    }
  }
}
</script>

<style  lang="scss">

</style>
