export default {
  formateDate (time, rule = '') {
    //new Date(time)生成时间对象d
    let date = new Date(time)
    let fmt = rule || 'yyyy-MM-dd hh:mm:ss'
    /* if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, date.getFullYear())
        } */
    const o = {
      'y+': date.getFullYear(),
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        const val = o[k] + ''
        fmt = fmt.replace(RegExp.$1, val.padStart(2, '0'))
        //  自动补零
      }
    }
    return fmt
  },
  checkParmas (params) {
    console.log(params)
    Object.keys(params).forEach(item => {
      if (!params[item]) delete params[item]
    })
    return params
  },
  timeFix () {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
  }
}
